@font-face {
  font-family: 'MiddleEarth';
  src: url('./font/MiddleEarth.ttf') format('truetype');
}

/* @font-face {
  font-family: 'MiddleEarth';
  src: url('./font/UnifrakturCook-Bold.ttf') format('truetype');
} */

::selection {
  background: rgba(185, 130, 75, 0.3);
}

body {
  margin: 0;
  padding: 0;
  font-family: MiddleEarth;
  overflow: hidden;
  cursor: crosshair;
  background-color: #161010;
  caret-color: rgb(22, 16, 16, 0.3);
}
