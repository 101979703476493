.Timer,
.IntroTimer {
  font-size: 4vw;
  color: white;
  text-shadow: 2px 0px 10px black;
  text-align: center;
}

.Timer-warn {
  color: red;
  text-shadow: 2px 0px 10px white;
}

.Timer {
  width: 100%;
  font-size: 10vw;
  display: flex;
  /* justify-content: space-between; */
  /* min-width: 32vw; */
}

.Timer-secs {
  flex: 1;
  text-align: left;
}
.Timer-mins {
  flex: 1;
  text-align: right;
}
.Timer-middle {
  flex-basis: 20px;
  flex-grow: 0;
  position: relative;
  bottom: 8px;
  margin: 0 13px;
}

.IntroTimer-blink {
  animation-duration: 3s;
  animation-name: timerBlink;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes timerBlink {
  from {
    opacity: 0.7;
  }

  to {
    opacity: 0.2;
  }
}
