.Game {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('./ring.jpg') no-repeat center center fixed;
  background-size: cover;

  display: flex;
  flex-direction: column;

  animation: 4s ease-out fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.Game-text {
  flex: 1;
  font-size: 10vh;
  padding: 4vh;
  background-color: rgba(0, 0, 0, 0.4);
  margin: 10vh;
  border: none;
  outline: 0px solid transparent;
  color: white;
  text-shadow: 2px 0px 10px black;
  text-align: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.Game-text:active,
.Game-text:focus {
  opacity: 1;
}

.Game-timer {
  display: flex;
  justify-content: center;
  /* position: absolute;
  bottom: 2vh;
  left: 0;
  right: 0; */
}
